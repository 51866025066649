@font-face {
  font-family: "db_heaventbold";
  src: url("./assets/fonts/db_heavent_bd_v3.2-webfont.eot");
  src: url("./assets/fonts/db_heavent_bd_v3.2-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/db_heavent_bd_v3.2-webfont.woff2") format("woff2"),
    url("./assets/fonts/db_heavent_bd_v3.2-webfont.woff") format("woff"),
    url("./assets/fonts/db_heavent_bd_v3.2-webfont.ttf") format("truetype"),
    url("./assets/fonts/db_heavent_bd_v3.2-webfont.svg#db_heaventbold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "db_heaventmed";
  src: url("./assets/fonts/db_heavent_med_v3.2-webfont.eot");
  src: url("./assets/fonts/db_heavent_med_v3.2-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/db_heavent_med_v3.2-webfont.woff2") format("woff2"),
    url("./assets/fonts/db_heavent_med_v3.2-webfont.woff") format("woff"),
    url("./assets/fonts/db_heavent_med_v3.2-webfont.ttf") format("truetype"),
    url("./assets/fonts/db_heavent_med_v3.2-webfont.svg#db_heaventmed")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "db_heaventthin";
  src: url("./assets/fonts/db_heavent_thin_v3.2-webfont.eot");
  src: url("./assets/fonts/db_heavent_thin_v3.2-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/db_heavent_thin_v3.2-webfont.woff2") format("woff2"),
    url("./assets/fonts/db_heavent_thin_v3.2-webfont.woff") format("woff"),
    url("./assets/fonts/db_heavent_thin_v3.2-webfont.ttf") format("truetype"),
    url("./assets/fonts/db_heavent_thin_v3.2-webfont.svg#db_heaventthin")
      format("svg");
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: "db_heavent";
  src: url("./assets/fonts/db_heavent_v3.2-webfont.eot");
  src: url("./assets/fonts/db_heavent_v3.2-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/db_heavent_v3.2-webfont.woff2") format("woff2"),
    url("./assets/fonts/db_heavent_v3.2-webfont.woff") format("woff"),
    url("./assets/fonts/db_heavent_v3.2-webfont.ttf") format("truetype"),
    url("./assets/fonts/db_heavent_v3.2-webfont.svg#db_heaventthin")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

$fill-green: #249627;
$fill-red: #d80012;
$fill-blue: #2b6fb6;
$fill-blue-font: #017aff;
$gradient-bluesky-top: #007dfe;
$gradient-bluesky-bottom: #00a3fe;
$fill-grey-font: #80879a;
$fill-grey-font-light: #ccc;
$fill-grey-line: #e4e4e4;
$fill-grey-light: #f9f9f9;
$gradient-grey-top: #646567;
$gradient-grey-bottom: #797a7c;
$fill-black: #000;
$fill-white: #fff;

$shadow: 3px 3px 10px rgba(0, 0, 0, 0.08);

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

hr {
  border: 1px solid $fill-grey-line;
}

h1 {
  font-size: 2.6rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: normal;
  font-family: "db_heaventmed";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 0.6;
}

ul {
  list-style: none;
}

button {
  border: none;
  margin: 0 10px;
  background-color: transparent;
  font-size: 1.4rem;

  &:focus {
    outline: none !important;
    outline-offset: none !important;
  }
}

.btn-back {
  background-color: rgba(255, 255, 255, 0.18);
  border-radius: 30px;
  padding: 11px;
  width: 42px;
  height: 42px;

  img {
    width: 18px;
    height: auto;
    margin-left: -2px;
  }
}

.btn-ellipse {
  border-radius: 30px;
  padding: 15px 20px;
  min-width: 140px;
  margin: 0;
  box-shadow: $shadow;

  &._grey {
    margin-right: 5px;
    background: rgb(99, 100, 102);
    background: -moz-linear-gradient(
      180deg,
      rgba(99, 100, 102, 1) 0%,
      rgba(122, 123, 123, 1) 100%
    );
    background: -webkit-linear-gradient(
      180deg,
      rgba(99, 100, 102, 1) 0%,
      rgba(122, 123, 123, 1) 100%
    );
    background: linear-gradient(
      180deg,
      rgba(99, 100, 102, 1) 0%,
      rgba(122, 123, 123, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#666769",endColorstr="#7a7b7c",GradientType=1);
  }

  &._blue {
    margin-left: 5px;
    background: rgb(0, 125, 254);
    background: -moz-linear-gradient(
      180deg,
      rgba(0, 125, 254, 1) 0%,
      rgba(0, 163, 254, 1) 100%
    );
    background: -webkit-linear-gradient(
      180deg,
      rgba(0, 125, 254, 1) 0%,
      rgba(0, 163, 254, 1) 100%
    );
    background: linear-gradient(
      180deg,
      rgba(0, 125, 254, 1) 0%,
      rgba(0, 163, 254, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#007dfe",endColorstr="#00a3fe",GradientType=1);
  }

  p {
    font-size: 1.6rem !important;
  }
}


.btn-capsule {
  background-color: $fill-white;
  width: auto;
  height: auto;
  padding: 8px 24px;
  margin: auto;
  border-radius: 15px;
}

.btn-round {
  // min-width: 180px;
  background-color: $fill-white;
  box-shadow: $shadow;
  width: auto;
  height: auto;
  padding: 18px 16%;
  margin: auto;
  border-radius: 15px;
}

.btn-square {
  max-width: 110px;

  .btn-image {
    border-radius: 15px;
    background-color: $fill-white;
    box-shadow: $shadow;
    width: 84px;
    height: 84px;
    padding: 24px;
    margin: auto;

    img {
      width: 36px;
      height: 36px;
    }
  }

  p {
    margin-top: 10px;
    font-size: 1.5rem;
    line-height: 0.8;
  }
}

.btn-row {
  width: 100%;
  display: flex;
  align-items: center;

  &._bottom {
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 13%;
    background-color: #fff;
    background: -moz-linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background: -webkit-linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="transparent",endColorstr="#ffffff",GradientType=1);
  }
}

.btn-fluid {
  button {
    width: 100%;
    margin-bottom: 10px;
  }
}

.bg-gradient-blue {
  background: rgb(0, 125, 254);
  background: -moz-linear-gradient(
    180deg,
    rgba(0, 125, 254, 1) 0%,
    rgba(0, 163, 254, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(0, 125, 254, 1) 0%,
    rgba(0, 163, 254, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(0, 125, 254, 1) 0%,
    rgba(0, 163, 254, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#007dfe",endColorstr="#00a3fe",GradientType=1);
}

.bg-gradient-white {
  border: 1px solid $fill-blue-font;

  > h3 {
    color: $fill-blue-font;
  }
}

.bg-gradient-grey {
  background: rgb(102, 102, 102);
  background: -moz-linear-gradient(
    180deg,
    rgba(102, 102, 102, 1) 0%,
    rgba(140, 140, 140, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(102, 102, 102, 1) 0%,
    rgba(140, 140, 140, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(102, 102, 102, 1) 0%,
    rgba(140, 140, 140, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#666666",endColorstr="#8a8a8a",GradientType=1);
}

.bg-transparent {
  background-color: transparent;
  border: 1px solid #fff;
}

.font-blue {
  color: $fill-blue-font;
}

.font-blue-dark {
  color: $fill-blue;
}

.font-black {
  color: $fill-black;
}

.font-red {
  color: $fill-red;
}

.font-green {
  color: $fill-green;
}

.font-grey {
  color: $fill-grey-font;
}

.font-white {
  color: $fill-white;
}

.font-bold {
  font-weight: bold;
}

._display-flex {
  display: flex;

  ._left {
    text-align: left;
    flex: 1;
  }

  ._right {
    text-align: right;
    flex: 1;
  }
}

.txt-center {
  text-align: center;
}

// Modal ----------------------------------------------------------//
._modal {
  width: 90%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 50% auto;
  transform: translate(0, 15%);

  ._error-modal,
  ._insertcode-modal {
    text-align: center;
    padding: 20px;

    p {
      color: $fill-black;
      font-size: 1.6rem;
      &.font-red {
        font-size: 1.2rem;
        color: $fill-red;
        text-align: left;
      }
    }

    input {
      border: 1px solid $fill-blue-font;
      color: $fill-blue-font;
      font-family: "db_heaventmed";
      border-radius: 10px 10px 10px 10px;
      font-size: 2rem;
      padding: 6px 6px;
      margin: 10px 0 20px;
      width: 100%;
    }

    button {
      p {
        color: $fill-white;
      }
    }
  }

  ._result {
    text-align: center;
    padding: 20px;
    font-size: 2rem;

    > div {
      margin: 10px 0 12px;
    }

    img {
      vertical-align: bottom;
      margin-right: 10px;
    }

    span,
    p {
      font-size: 1.6rem;
      margin-right: 8px;
      line-height: 1;

      &:last-child {
        font-size: 2rem;
        margin: 0;
      }

      &.font-blue {
        font-size: 2.6rem;
      }
    }

    button {
      margin-top: 18px;
      width: 180px;

      .btn-ellipse {
        p {
          color: $fill-white;
          font-size: 1.6rem;
        }
      }
    }
  }
}

._resultSummary {
  text-align: center;
  padding: 10px 20px 20px;
  font-size: 2rem;

  > div {
    margin: 10px 0 30px;
  }

  img {
    vertical-align: bottom;
    margin-right: 10px;
  }

  span {
    font-size: 2.6rem;
    margin-right: 8px;
    line-height: 1;

    &:last-child {
      font-size: 2rem;
      margin: 0;
    }
  }

  button {
    width: 180px;
  }
}

.ReactModal__Overlay {
  z-index: 10;
}

// Core ----------------------------------------------------------//

body {
  background-color: $fill-white;
  color: $fill-grey-font;
  font-size: 1.4rem;
  font-family: "db_heaventmed", sans-serif;
}

body,
html {
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
}

#root {
  .btn-back {
    position: absolute;
    top: 20px;
    left: 20px;
  }
}

.loading {
  &.container {
    .container-top {
      height: 100vh;

      h3 {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 100px;
        height: 80px;
      }
    }
  }
}

.header {
  text-align: center;
  padding: 10px 0;
  width: 100vw;
  position: relative;

  img {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.footer {
  // position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 20px 0;
  background-color: $fill-blue-font;

  p,
  a {
    color: $fill-white;
    font-size: 1rem;
  }
}

.nav-main {
  border: 1px solid $fill-blue-font;
  padding: 20px 36px;
  text-align: center;

  button {
    .btn-image {
      background-color: $fill-white;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      padding: 20px;
      box-shadow: $shadow;
      margin-bottom: 10px;

      img {
        width: 40px;
        height: 40px;
      }
    }

    p {
      color: $fill-blue-font;
      font-size: 1.4rem;
    }
  }
}

.container {
  //   padding-bottom: 70px;

  .landing {
    width: 100vw;
    height: 88vh;

    .landing-box {
      text-align: center;
      width: 200px;
      height: 240px;
      position: absolute;
      top: -18%;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;

      h1,
      h3 {
        margin-bottom: 10px;
      }
    }
  }

  .container-top {
    padding: 30px 20px;
    text-align: center;
    background: rgb(0, 125, 254);
    background: -moz-linear-gradient(
      180deg,
      rgba(0, 125, 254, 1) 0%,
      rgba(0, 163, 254, 1) 100%
    );
    background: -webkit-linear-gradient(
      180deg,
      rgba(0, 125, 254, 1) 0%,
      rgba(0, 163, 254, 1) 100%
    );
    background: linear-gradient(
      180deg,
      rgba(0, 125, 254, 1) 0%,
      rgba(0, 163, 254, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#007dfe",endColorstr="#00a3fe",GradientType=1);

    h3 {
      color: $fill-white;
      margin-bottom: 30px;
      font-size: 2rem;
    }

    .btn-back {
      position: absolute;
      left: 20px;
      top: 20px;
    }
  }
}

// All Box Content -----------------------------------------------------//
.box-style {
  background-color: $fill-white;
  border-radius: 20px;
  border: 1px solid #eef2f5;
  margin-bottom: 30px;
  box-shadow: $shadow;
}

.action-box {
  padding: 20px;

  img {
    vertical-align: middle;
  }

  ._header {
    text-align: left;

    img {
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }
  }

  ._input {
    width: 100%;
    border-bottom: 1px solid $fill-grey-line;
    padding: 10px 0;

    .decreaseBtn {
      float: left;
    }

    .amountPoint,
    .amountDiscount,
    .input-text {
      display: inline-block;
      font-size: 2rem;
      line-height: 0.8;
      border: none;
      width: 74%;
      text-align: center;
    }

    .input-text {
      font-family: "db_heaventmed";
      font-size: 1.6rem;
      text-align: left;
      width: 98%;
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $fill-grey-font-light;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $fill-grey-font-light;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $fill-grey-font-light;
      }

      &.font-red {
        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $fill-red;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $fill-red;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: $fill-red;
        }
      }
    }

    .increaseBtn {
      float: right;
    }
  }

  ._error {
    padding: 5px 0 0;
    display: none;

    &.show {
      display: block;
    }

    p {
      text-align: left;
      color: $fill-red;
    }
  }
  ._badge {
    margin: 5px 0 30px;

    ._badgelist {
      display: flex;
      margin: 15px 0 0;

      ._badgeitem {
        flex: 1;
        padding: 5px;
        border: 1px solid $fill-blue;
        border-radius: 30px;
        font-size: 1.2rem;
        color: $fill-blue;
        margin: 0 4px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    ._info {
      margin-top: 30px;

      p {
        text-align: left;

        span {
          text-align: right;
          float: right;

          b {
            font-weight: normal;
          }
        }
      }
    }
  }

  ._footer {
    display: flex;
    padding-bottom: 10px;

    ._left,
    ._right {
      span {
        line-height: 1.8;
      }
    }

    ._left {
      flex: 2;
    }

    ._right {
      flex: 4;

      img {
        margin: -5px 5px 0 0;
      }

      .font-blue {
        font-size: 2rem;
        line-height: 1;
        margin-right: 5px;
      }
    }
  }
}

.confirm-box {
  padding: 20px;

  img {
    vertical-align: middle;
    margin: -10px 10px 0 0;
  }

  ._result {
    .font-blue {
      font-size: 2.6rem;
      margin-right: 8px;

      &:last-child {
        font-size: 2rem;
        margin: 0;
      }
    }
  }
}

.summary-box {
  padding: 20px;

  ._point {
    padding-bottom: 20px;
    border-bottom: 1px solid $fill-grey-line;
  }

  ._balance {
    padding-top: 20px;

    .font-red {
      font-size: 2rem;
      margin-right: 8px;
      line-height: 0.6;
    }
  }

  .font-black {
    font-size: 2rem;
    line-height: 0.6;
    margin-right: 8px;
  }
}

.ticket-box {
  padding: 20px 20px 30px;
  position: relative;

  &.box-style {
    border-radius: 0;
  }

  ._header {
    h3 {
      margin-bottom: 10px;
      font-size: 2rem;
    }

    p {
      font-size: 1.2rem;
    }
  }

  ._content {
    padding-bottom: 20px;
    .qrcode-box {
      border: 2px solid $fill-blue-font;
      width: 180px;
      height: 180px;
      margin: 15px auto;
      padding: 3px;

      // .qrcode {
      //   width: 176px;
      //   height: 176px;
      // }
    }
  }

  ._footer {
    h3 {
      margin: 5px 0 16px;
      line-height: 0.8;
    }
  }

  .ticket-footer {
    width: 100%;
    height: 50px;
    background-image: url(./assets/img/ticket-bottom.png);
    background-repeat: repeat-x;
    background-size: 30px;
    position: absolute;
    left: 0;
    bottom: -36px;
  }
}

// Main Page --------------------------------------------------------//

.main-box {
  &._flex {
    display: flex;
  }

  > div {
    width: 49.5%;
    padding: 14px 0;
    flex: 2 1;

    &._left {
      border-right: 1px solid #ccc;
    }

    img {
      display: inline-block;
      width: 36px;
      height: 36px;
      margin-right: 8px;
    }

    .main-text {
      display: inline-block;
      text-align: left;

      p {
        font-size: 1.2rem;
      }
    }
  }
}

.main-action {
  margin-top: -40px;
  padding-bottom: 30px;
  text-align: center;

  ul {
    list-style: none;
    li {
      width: 116px;
      display: inline-block;
      margin-top: 10px;
    }
  }

  > * {
    display: inline-block;
  }
}

.main-attention {
  margin: 0 20px 24px;
  border-radius: 0;

  .main-quota {
    .box-style {
      padding: 10px 20px;
      margin: 0 0 10px;
      text-align: center;
    }
  }

  .box-style {
    padding: 20px;
    text-align: left;
  }
}

.main-transaction {
  padding: 20px;

  .main-transaction-title {
    color: $fill-grey-font;
    margin-bottom: 15px;
  }

  .main-transaction-item {
    flex-direction: row;
    display: flex;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 15px;
    box-shadow: $shadow;

    ._img {
      flex: 1;
      display: inline-block;
      width: 36px;
      height: 36px;

      img {
        width: 36px;
        height: 36px;

        &.ic-scan {
          width: 32px;
          height: 32px;
        }
      }
    }

    ._detail {
      flex: 8;
      margin-left: 15px;

      ._status,
      ._idtimestmp {
        span {
          display: inline-block;
          width: 50%;
          word-break: break-word;

          &:last-child {
            text-align: right;
          }
        }
      }

      ._status {
        line-height: 1;

        span {
          &:last-child {
            font-size: 2rem;
            line-height: 0.6;
            vertical-align: top;
          }
        }
      }

      ._idtimestmp {
        line-height: 0.6;

        span {
          width: 50%;
          font-size: 1rem;
          line-height: 0.6;
          vertical-align: top;

          &:last-child {
            width: 50%;
          }
        }
      }
    }
  }
}

// Click-to-collect ------------------------------------------------//
.click-to-collect {

  .light {
    // bottom: 15%;
    position: absolute;
    animation: linear infinite;
    animation-name: run;
    animation-duration: 3s;
    background-color: rgba(255, 255, 255, 0.15);
    transform: skewX(-45deg);
  }
  @keyframes run {
    0% {
      left: -270px;
      // transform: translateX(0);
    }
    25% {
      left: 370px;

    }
    100% {
      left: 370px;
      // transform: translateX(270px);
      // transform: scaleX(-1);
    }
  }
  
  .headertext {
    font-size: 1.4rem;
    padding-left: 20px;
  }

  ._blue {
    .btn-capsule {
      color: #071C9E;
    }
  }

  .btn-capsule {
    color: #98570D;
    font-size: 1rem;
  }

  .click-to-collect-box {
    padding: 4px 14px 0;
    overflow: auto;
    width: 100%;
  
    .coupon-list-box {
      display: inline-flex;
      // width: 1000px;
      padding-bottom: 0;
      position: relative;


    &.one-coupon {
      display: flex;
      justify-content: center;
    }
  
      .couponcard {
        width: 270px;
        border: none;
        margin: 6px;
        border-radius: 14px;
        min-height: auto;

        ._content {
          position: relative;
        }

        &.selected {
          pointer-events: none;
          opacity: 0.5;
          
          .light {
            display: none;
          }
          
          .btn-capsule {
            visibility: hidden;
          }
        }
  
        ._left {
          .subheadertext {
            font-size: 1em; // 1.2rem
          }
          .subtext {
            line-height: .6;
            margin: 0 0 4px;
            height: 0.5rem;
          }
          h1 {
            line-height: 0.6;
            position: relative;
            color: #fff;
            // font-size: 44px;
            z-index: 1;
          }

          h1:before{
            content: attr(data-text);
            color: #fff;
            position: absolute;
            left: 0;
            z-index: 2;
          }

          h1:after {
            content: attr(data-text);
            position: absolute;
            left: 0;
            z-index: 0;
            // text-shadow:
            // -2px -2px 0 #98570D,  
            //  2px -2px 0 #98570D,
            //  -2px 2px 0 #98570D,
            //   2px 2px 0 #98570D;
            // -webkit-text-stroke: 5px #B76C15;
          }

          span {
            position: relative;
            color: #fff;
            display: inline-block;
          }

          span:before {
            content: 'บาท';
            color: #fff;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 2;
          }

          span:after {
            content: 'บาท';
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 0;
            // text-shadow:
            // -2px -2px 0 #98570D,  
            //  2px -2px 0 #98570D,
            //  -2px 2px 0 #98570D,
            //   2px 2px 0 #98570D;
            // -webkit-text-stroke: 5px #B76C15;
          }
        }
  
        .notice {
          font-size: 0.75em; // 1rem
          padding: 3px 10px;
          // border-radius: 6px;
        }
  
        ._bottom {
           display: none
        }

        &._orange {
          .notice {
            color: #98570D;
            background-color: #FEBA5C;
          }
          h1:after {
            // text-shadow:
            // -2px -2px 0 #98570D,  
            //  2px -2px 0 #98570D,
            //  -2px 2px 0 #98570D,
            //   2px 2px 0 #98570D;
            -webkit-text-stroke: 5px #B76C15;
          }
          span:after {
            // text-shadow:
            // -2px -2px 0 #98570D,  
            //  2px -2px 0 #98570D,
            //  -2px 2px 0 #98570D,
            //   2px 2px 0 #98570D;
            -webkit-text-stroke: 5px #B76C15;
          }
        }

        &._red {
          .notice {
            color: #fff;
            background-color: #EC5757;
          }
          h1 {
            font-size: 1.8rem;
          }
          h1:after {
            // text-shadow:
            // -2px -2px 0 #98570D,  
            //  2px -2px 0 #98570D,
            //  -2px 2px 0 #98570D,
            //   2px 2px 0 #98570D;
            -webkit-text-stroke: 5px #9E0302;
          }
          span:after {
            // text-shadow:
            // -2px -2px 0 #98570D,  
            //  2px -2px 0 #98570D,
            //  -2px 2px 0 #98570D,
            //   2px 2px 0 #98570D;
            -webkit-text-stroke: 5px #9E0302;
          }
        }

        &._blue {
          .notice {
            color: #fff;
            background-color: #7D9EDA;
          }
          h1 {
            font-size: 1.8rem;
          }
          h1:after {
            // text-shadow:
            // -2px -2px 0 #98570D,  
            //  2px -2px 0 #98570D,
            //  -2px 2px 0 #98570D,
            //   2px 2px 0 #98570D;
            -webkit-text-stroke: 5px #01499E;
          }
          span:after {
            // text-shadow:
            // -2px -2px 0 #98570D,  
            //  2px -2px 0 #98570D,
            //  -2px 2px 0 #98570D,
            //   2px 2px 0 #98570D;
            -webkit-text-stroke: 5px #01499E;
          }
        }
      }
    }
    

  }
}

.modal-errClickToCollect {
  .msg {
    h3 {
      color: #000;
      line-height: 1;
    }
  }
}


// Coin Page --------------------------------------------------------//
.buycoin-action {
  margin-top: -60px;
  padding: 30px 20px;
  text-align: center;
}

.checkcoin-action {
  margin-top: -60px;
  padding: 30px 20px;
  text-align: center;
}

// Coupon Page -----------------------------------------------------//
.createcoupon-action {
  margin-top: -110px;
  padding: 30px 20px;
  text-align: center;
}

// Coupon SKU Page --------------------------------------------------------//
.createcouponsku-action {
  margin-top: -60px;
  padding: 30px 20px;
  text-align: center;

  .sku-option,
  .react-tabs {
    display: flex;
    margin-bottom: 20px;
    height: 22vh;

    $breakpoint-xs-mobile: 320px;
    @media (min-height: $breakpoint-xs-mobile) {
      height: 24vh;
    }
    ._left-option {
      flex: 1;
      padding-right: 5px;
      overflow: auto;
      // height: 27.5vh;

      $breakpoint-sm-mobile-height: 812px;
      @media (min-height: $breakpoint-sm-mobile-height) {
        height: 22vh;
      }
      ul {
        list-style: none;
        li {
          text-align: left;
          padding: 6px 4px;
          line-height: 0.8;
          border-bottom: 1px solid $fill-grey-line;
          color: $fill-black;
        }
      }
    }
    ._right-option {
      flex: 1;
      padding-left: 5px;
      display: block;
      .sku-img {
        width: 100%;
        height: 16vh;
        background-color: $fill-grey-light;
        display: inline-block;
        position: relative;
        img {
          position: absolute;
          margin: auto;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          max-width: 100%;
          max-height: 100%;
        }
      }
      .sku-input {
        padding: 4px 0;
        img {
          width: 32px;
          height: 32px;
        }
        input {
          width: 50%;
          font-size: 1.6rem;

          $breakpoint-xs-mobile: 320px;
          @media (max-width: $breakpoint-xs-mobile) {
            width: 43%;
          }
        }
      }

      .amountSku {
        display: inline-block;
        font-size: 2rem;
        line-height: 0.8;
        border: none;
        width: 74%;
        text-align: center;
      }
    }
  }

  .react-tabs__tab--selected {
    background-color: $fill-grey-line;
  }

  .sku-list {
    border-bottom: 1px solid $fill-grey-line;
    margin-bottom: 20px;
    display: flex;
    ul {
      list-style: none;
      padding-bottom: 10px;
      flex: 4;
      li {
        text-align: left;
      }
      &:last-child {
        flex: 1;
        li {
          text-align: right;
        }
      }
    }
  }

  ._heade-top {
    margin-bottom: 20px;
    text-align: left;
  }

  .action-box {
    ._input {
      $breakpoint-xs-mobile: 320px;
      @media (max-width: $breakpoint-xs-mobile) {
        width: 99%;
      }

      .amountDiscount {
        $breakpoint-xs-mobile: 320px;
        @media (max-width: $breakpoint-xs-mobile) {
          width: 70%;
        }
      }
    }
    ._footer {
      ._left {
        flex: 2.8;
      }
    }
  }
}

// Receive Coupon Page --------------------------------------------------------//
.receivecoupon-action {
  margin-top: -60px;
  padding: 30px 20px;
  text-align: center;

  .action-box {
    ._heade-top {
      text-align: left;
      margin-bottom: 20px;
    }
    ._footer {
      ._left {
        flex: 2.5;
      }
    }
    ._footer-bottom {
      p {
        text-align: left;
        text-decoration: underline;
      }
    }
  }
}

.modal-couponreceiveaction-product {
  .action-box {
    .modal-head {
      margin-bottom: 15px;
    }
    ._footer {
      ._right {
        flex: 3 1;
      }
    }
  }

  ._footer {
    ._left {
      flex: 2.5;
    }
  }

  table {
    width: 100%;
    margin-bottom: 15px;
    border-bottom: 1px solid $fill-grey-line;
    thead {
      th {
        padding: 4px;
        font-weight: normal;
        border-bottom: 1px solid $fill-grey-line;
        font-size: 20px;
        &:first-child {
          text-align: left;
        }
      }
    }
    td,
    th {
      color: $fill-black;
      line-height: 0.8;
    }
    tbody {
      tr {
        td {
          padding: 4px;
          &:first-child {
            color: $fill-grey-font;
            text-align: left;
          }
        }
      }
    }
  }
}

.modal-couponcreatesku-salediscount {
  .action-box {
    ._input {
      margin-bottom: 10px;
    }
    p.font-red {
      text-align: center;
      margin-bottom: 10px;
    }
  }
  .action-box-bottom {
    margin-top: 20px;
    .btn-ellipse {
      min-width: 130px;
    }
  }
}

// Scan page ------------------------------------------------------//
.scanqrcode-box {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $fill-black;
  overflow: hidden;

  h3 {
    margin: 0;
    font-size: 2rem;
    color: $fill-white;
  }

  p {
    // font-size: 1.4rem;
    color: $fill-white;
  }

  .btn-back {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  .qrscanner {
    position: absolute;
    height: 75%;
    margin: auto;
    left: 0;
    right: 0;
    top: 2%;
    bottom: 0;
    z-index: 1;
  }

  ._header {
    position: relative;
    // top: 0;
    // left: 0;
    padding: 20px;
    width: 100%;
    z-index: 2;
    height: 79px;
    p {
      font-size: 16px;
    }
  }

  ._browsefile {
    position: absolute;
    top: 20px;
    right: 10px;
    z-index: 3;
  }

  ._footer {
    position: fixed;
    bottom: 10px;
    left: 0;
    padding: 18px 20px 30px;
    width: 100%;
    z-index: 2;
    height: 74px;
  }
}

// Html5-qrcode ------------------------------------------------------//
.component-qr-scanner {
  width: 100vw;
  height: 100vh;
  // min-width: 640px;
  // min-height: 480px;
  background: #eee;
  position: relative;
  resize: both;
}

.qr-bg-loading {
  animation: 1s linear infinite dbrScanner-rotate;
  width: 20%;
  height: 20%;
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  fill: #aaa;
}

.camera-reader {
  // height: 100vh - 21;
  // overflow: hidden;

  > div:first-child {
    display: none;
  }
}

#camera-reader__scan_region {
  margin-top: 79px;
  // height: 100vh - 48;
  position: relative;
  background: url(./assets/img/ic-scanqr.svg) no-repeat;
  background-size: 20%;
  background-position: center;
  img {
    opacity: 0.3;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 50%;
    display: none;
  }
}

#camera-reader__dashboard {
  background-color: $fill-black;
}

// Camera section
#camera-reader__dashboard_section_csr {
  > div {
    > button {
      text-align: center;
      padding: 10px 20px;
      font-family: "db_heaventmed";
      color: $gradient-bluesky-top;
      width: 250px;
      background-color: $gradient-bluesky-top;
      display: inline-block;
      border-radius: 30px;
      position: relative;
      &:first-child {
        &::before {
          position: absolute;
          left: 0;
          right: 0;
          margin-top: auto;
          color: $fill-white;
          content: "กดเพื่อสแกน QR Code";
          font-size: 24px;
        }
      }
    }
  }
  > span:first-child {
    color: #000;
    display: inline-block;
    margin: 0 0 25px !important;
    text-align: center;
    &::before {
      position: absolute;
      left: 0;
      right: 0;
      margin-top: auto;
      height: 20px;
      color: $fill-white;
      content: "กรุณาเลือกกล้อง";
      font-size: 24px;
    }
    &::after {
      position: absolute;
      left: 0;
      right: 0;
      margin-top: auto;
      height: 20px;
      color: $fill-white;
      content: "facing front = กล้องหน้า, facing back = กล้องหลัง";
      font-size: 18px;
    }
  }
  > span:last-child {
    > button {
      text-align: center;
      padding: 10px 20px;
      font-family: "db_heaventmed";
      color: $gradient-bluesky-top;
      width: 250px;
      background-color: $gradient-bluesky-top;
      display: inline-block;
      border-radius: 30px;
      position: relative;
      &:first-child {
        &::before {
          position: absolute;
          left: 0;
          right: 0;
          margin-top: auto;
          color: $fill-white;
          content: "สแกน QR Code";
          font-size: 24px;
        }
      }
      &:last-child {
        &::before {
          position: absolute;
          left: 0;
          right: 0;
          margin-top: auto;
          color: $fill-white;
          content: "ยกเลิกสแกน QR Code";
          font-size: 24px;
        }
      }
    }
  }
  #camera-reader__camera_selection {
    padding: 10px;
    display: block;
  }
}

// Image section
#camera-reader__dashboard_section_fsr {
  width: 250px;
  padding: 10px;
  background: #fff;
  border-radius: 30px;
  display: inline-block;
  margin: auto;
  &::before {
    position: absolute;
    left: 0;
    right: 0;
    margin-top: auto;
    color: $fill-black;
    content: "เลือกรูปภาพ";
    font-size: 24px;
  }
  #camera-reader__filescan_input {
    opacity: 0;
  }
  span {
    display: none;
  }
}

#camera-reader__dashboard_section_swaplink {
  margin-top: 10px;
  text-align: center;
  padding: 10px 20px;
  font-family: "db_heaventmed";
  color: $gradient-grey-top;
  width: 250px;
  background-color: $gradient-grey-top;
  display: inline-block;
  border-radius: 30px;
  position: relative;
  &:first-child {
    &::before {
      position: absolute;
      left: 0;
      right: 0;
      margin-top: auto;
      color: $fill-white;
      content: "อัพโหลดรูป QR Code";
      font-size: 24px;
    }
  }
}

// Dynamsoft ------------------------------------------------------//
.component-barcode-scanner {
  width: 100vw;
  height: 100vh;
  // min-width: 640px;
  // min-height: 480px;
  background: #eee;
  position: relative;
  resize: both;
}
.dbrScanner-bg-loading {
  animation: 1s linear infinite dbrScanner-rotate;
  width: 20%;
  height: 20%;
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  fill: #aaa;
}
.dbrScanner-bg-camera {
  width: 40%;
  height: 40%;
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  fill: #aaa;
}
.dbrScanner-video {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
}
.dbrScanner-cvs-drawarea {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.dbrScanner-cvs-scanarea {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.dbrScanner-scanlight {
  width: 100%;
  height: 3%;
  position: absolute;
  animation: 3s infinite dbrScanner-scanlight;
  border-radius: 50%;
  box-shadow: 0px 0px 2vw 1px #00e5ff;
  background: #fff;
}
.dbrScanner-sel-camera {
  margin: 0 auto;
  position: absolute;
  left: 0;
  top: 0;
}
.dbrScanner-sel-resolution {
  position: absolute;
  left: 0;
  top: 20px;
}
@keyframes dbrScanner-rotate {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
@keyframes dbrScanner-scanlight {
  from {
    top: 0;
  }
  to {
    top: 97%;
  }
}

// E Coupon ----------------------------------------- //

.overlay {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  align-items: center;
  p {
    width: 100%;
    text-align: center;
  }
}

.main-status {
  padding: 0 20px;
  margin-top: -40px;

  p {
    text-align: center;
  }

  .main-box {
    margin: 0 0 20px;

    &.box-square {
      border-radius: 0;
    }

    .main-notice {
      padding: 20px;
      text-align: center;
      width: 100%;
      color: #000;
    }

    .main-text {
      display: flex;
      padding: 0 10px;
      text-align: left;
      align-items: center;
      word-break: break-all;

      img {
        flex-grow: 1;
        margin: 0;
      }

      h1 {
        // margin-left: 5px;
        flex-grow: 1;
        font-size: 2.4rem;

        span {
          font-size: 1.4rem;
          color: #797a7c;
        }
      }
    }
  }
  &.main-genqr {
    .ticket-box {
      padding-bottom: 60px;
      p {
        font-size: 1.4rem;
        line-height: 1;
      }

      ._header {
        h3 {
          margin-bottom: 10px;
        }
      }

      ._content {
        .font-blue {
          font-size: 2rem;
        }
        b {
          font-family: "db_heaventbold";
          color: #000;
          margin: 10px 0;
          display: inline-block;
          font-weight: normal;
          font-size: 1.4rem;
        }
        .btn-row {
          width: 80%;
          margin-top: 10px;
          h3 {
            margin-bottom: 0;
          }
        }
      }
      .main-notice {
        &.font-blue {
          font-size: 1.4rem;
        }
      }
    }

    .box-qr {
      margin: auto;
      width: 200px;
      text-align: center;
      border: 4px solid $fill-blue-font;
    }
  }
}

.empty-coupon {
  padding: 0 0 0;
  img {
    margin-bottom: 20px;
  }
  h3 {
    margin-bottom: 10px;
  }
  p {
    line-height: 1;
  }
}

.coupon-list-box {
  overflow: auto;
  padding-bottom: 60px;
}

.couponcard {
  min-height: 130px;
  border-radius: 20px 20px 20px 20px;
  color: #fff;
  margin-bottom: 9px;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  border: 6px solid transparent;

  &._selected {
    border-radius: 21px 21px 21px 21px;
    border: 6px solid $gradient-bluesky-top;
    &._red {
      background-color: #d61c1b;
    }
  }

  ._content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 3% 4% 4% 9%;
  }

  ._row {
    display: flex;
    width: 100%;
    align-items: baseline;
  }

  ._left {
    flex-grow: 2;
    p {
      font-size: 1rem;
    }
    h1 {
      line-height: 0.7;
    }
    h3 {
      line-height: 0.9;
    }
    span {
      font-size: 1.8rem;
    }
  }

  ._right {
    flex-grow: 1;
    text-align: right;
    position: relative;
    img {
      width: 1.7rem;
      position: absolute;
      margin: auto;
      left: auto;
      right: 0;
      top: 0;
      bottom: 0;
      visibility: hidden;

      &._selected {
        visibility: visible;
      }
    }
    p {
      font-size: 1rem;
    }
  }
  ._bottom {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0;
    left: 0;
    // padding: 6px 0 0;
    p {
      font-size: 1.2rem;
      // line-height: 0.6;
    }
  }

  &._red {
    ._bottom {
      p {
        background-color: #eb5757;
        padding-top: 6px;
        line-height: 1;
      }
    }
  }
  &._black {
    ._bottom {
      p {
        background-color: #828384;
        padding-top: 6px;
        line-height: 1;
      }
    }
  }
}

.btn-ellipse {
  &._3d {
    box-shadow: 0 5px 0 #2989f5;
    width: 50%;
  }
}

.btn-transparent {
  background-color: transparent;
  border: 2px solid #fff;
}

.btn-fix-bottom {
  // position: absolute;
  bottom: 30px;
  margin: auto;
  left: 0;
  right: 0;
}

// E Coupon React Tabs -----------------------------------------------------//
.react-tabs {
  .react-tabs__tab {
    width: 50%;
    text-align: center;
  }

  .react-tabs__tab--selected {
    border: none;
    border-bottom: 2px solid #007dfe;
  }

  .react-tabs__tab-panel {
    padding: 4px 14px 0;
  }
}
// E Coupon Modal --------------------------------------------------//
.modal-selectcoupon {
  transform: translate(0, -30%);
}

.modal-content {
  padding: 20px;
  b {
    font-family: "db_heaventbold";
  }

  > h3 {
    color: #000;
    line-height: 1;
    margin-bottom: 10px;
  }

  > div > h3 {
    color: #000;
    line-height: 1;
    margin-bottom: -10px;
  }

  .modal-coupon-list {
    text-align: center;
    margin: 5px 0 15px;
    max-height: 120px;
    overflow: auto;

    li {
      margin-bottom: 10px;
    }

    h3 {
      color: $fill-blue-font;
    }
    p {
      font-size: 1rem;
      color: $fill-grey-font;
    }
  }

  .modal-insertcode {
    input {
      border: 1px solid $fill-blue-font;
      color: $fill-blue-font;
    }
  }
}

// Responsive -----------------------------------------------------//
// @media (min-width: 415px) {
//   .couponcard {
//     border-radius: 33px 33px 33px 33px;
//     &._selected {
//       border-radius: 33px 33px 33px 33px;
//     }
//   }
// }

@media (max-width: 413px) {
  // .couponcard {
  //   min-height: 128px;
  // }
  .btn-row {
    &._bottom {
      transform: translateY(6px);
    }
  }
}

@media (max-width: 374px) {
  // .couponcard {
  //   min-height: 128px;
  // }
  .btn-row {
    &._bottom {
      transform: translateY(16px);
    }
  }
}

@media (max-width: 320px) {
  h1 {
    font-size: 2.4rem;
  }

  button {
    margin: 0 5px;
  }

  .btn-round {
    h3 {
      font-size: 1.4rem;
    }
  }

  .btn-ellipse {
    min-width: 120px;
    &._3d {
      width: 80%;
    }
  }

  .action-box-bottom {
    .btn-ellipse {
      min-width: 110px;
    }
  }

  .coupon-list-box {
    padding-bottom: 40px;
  }

  .empty-coupon {
    img {
      width: 15%;
      margin-bottom: 10px;
    }
  }

  .main-status {
    .main-box {
      div {
        img {
          margin: 0;
        }
      }
      .main-text {
        h1 {
          font-size: 1.6rem;
          span {
            font-size: 1.2rem;
          }
        }
      }
    }
    
    &.main-genqr {
      .ticket-box {
        ._content {
          .btn-row {
            margin: 0 auto;
          }
        }
      }
    }
  }

  .buycoin-action {
    .buycoin-action-box {
      ._footer {
        ._right {
          flex: 2;
        }
      }
    }
  }

  // .click-to-collect {
    .couponcard {

      ._content {
        padding-top: 2%;
        // padding-left: 3%;

        h1 {
          font-size: 1.8rem;
        }
        h3 {
          font-size: 1.4rem;
        }
      }
    }

  // }

  .click-to-collect {
    ._content {
      padding-top: 0%;
    }
  }

  // .couponcard {
  //   min-height: 114px;
  // }
}
